import { useCompanyStore } from "@/store/company"
import { usePostHog } from "posthog-js/react"
import { Suspense, lazy, useEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom"
import { Navbar, PageLoader, RequireAuth } from "./components"
import { useHasReport } from "./hooks/useHasReport"
import { useUserStore } from "./store/user"

const VersionDisplay = lazy(() => import("./layouts/VersionDisplay"))
const ErrorComponent = lazy(() => import("./components/ErrorComponent"))
const AdministrativeAreaGoalsSales = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaGoals/AdministrativeAreaGoalsSales"
    )
)
const AdministrativeAreaUsers = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaUsers")
)
const AdminsitrativeAreaGoalsPurchases = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaGoals/AdministrativeAreaGoalsPurchases"
    )
)
const AdministrativeAreaGoalsSalesDetails = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaGoals/AdministrativeAreaGoalsSales/AdministrativeAreaGoalsSalesDetails"
    )
)
const AdministrativeArea = lazy(() => import("./routes/AdministrativeArea"))
const DRE = lazy(() => import("./routes/Reports/Board/DRE"))
const TiresGathering = lazy(
  () => import("./routes/Reports/Board/TiresGathering")
)
const BudgetAnalysis = lazy(
  () => import("./routes/Reports/Board/BudgetAnalysis")
)
const LogisticChecklist = lazy(
  () => import("./routes/Reports/Logistic/LogisticChecklist")
)
const OperationsChecklist = lazy(
  () => import("./routes/Reports/Logistic/OperationsChecklist")
)
const AdministrativeAreaCompanyInformation = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaCompany")
)
const AdministrativeAreaCommissions = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaCommissions")
)
const AdministrativeAreaPermissions = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaPermissions")
)
const AdministrativeAreaIndicators = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaIndicators")
)
const AdministrativeAreaParametrizationFinancial = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationFinancial"
    )
)
const AdministrativeAreaParametrizationCheckin = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationCheckin"
    )
)
const AdministrativeAreaParametrizationCheckinBulkSchedulingCreate = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationCheckin/AdministrativeAreaParametrizationCheckinBulkScheduling/AdministrativeAreaParametrizationCheckinBulkSchedulingCreate"
    )
)
const AdministrativeAreaParametrizationPotential = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationPotential"
    )
)
const AdministrativeAreaParametrizationSellers = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationSellers"
    )
)
const AdministrativeAreaParametrizationMarketClassificationTabs = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationMarketClassification"
    )
)
const AdministrativeAreaParametrizationReturns = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationReturns"
    )
)
const AdministrativeAreaParametrizationPortfolio = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationPortfolio"
    )
)
const AdministrativeAreaParametrizationCalendar = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationCalendar"
    )
)
const AdministrativeAreaParametrizationInventory = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaParametrizationInventory"
    )
)
const AdministrativeAreaCampaigns = lazy(
  () => import("./routes/AdministrativeArea/AdministrativeAreaCampaigns")
)
const AdministrativeAreaCampaignsDetails = lazy(
  () =>
    import(
      "./routes/AdministrativeArea/AdministrativeAreaCampaigns/AdministrativeAreaCampaignsDetails"
    )
)
const AmericanaReceivableMonitoring = lazy(
  () => import("./routes/Reports/Financial/AmericanaReceivableMonitoring")
)
const ReceivableMonitoring = lazy(
  () => import("./routes/Reports/Financial/ReceivableMonitoring")
)
const CurrentInventory = lazy(
  () => import("./routes/Reports/Inventory/CurrentInventory")
)
const InventoryMovement = lazy(
  () => import("./routes/Reports/Inventory/InventoryMovement")
)
const TanksControl = lazy(
  () => import("./routes/Reports/Inventory/TanksControl")
)
const CustomerFrequency = lazy(
  () => import("./routes/Reports/Sales/CustomerFrequency")
)
const GlobalInventory = lazy(
  () => import("./routes/Reports/Inventory/GlobalInventory")
)
const MarketClassification = lazy(
  () => import("./routes/Reports/Sales/MarketClassification")
)
const SalesByLocation = lazy(
  () => import("./routes/Reports/Sales/SalesByLocation")
)
const Dashboard = lazy(() => import("./routes/Dashboard"))
const EvolutionCurve = lazy(
  () => import("./routes/Reports/Sales/EvolutionCurve")
)
const ExchangeCenter = lazy(
  () => import("./routes/Reports/Inventory/ExchangeCenter")
)
const InventoryAdjustment = lazy(
  () => import("./routes/Reports/Inventory/InventoryAdjustment")
)
const PurchaseCenter = lazy(
  () => import("./routes/Reports/Inventory/PurchaseCenter")
)
const GmadCampaign = lazy(
  () => import("./routes/Reports/SalesSupervisor/GmadCampaigns")
)
const BrandGoals = lazy(
  () => import("./routes/Reports/SalesSupervisor/BrandGoals")
)
const CustomerOneDepartment = lazy(
  () => import("./routes/Reports/SalesSupervisor/CustomerOneDepartment")
)
const MahleAward = lazy(
  () => import("./routes/Reports/SalesSupervisor/MahleAward")
)
const GmadRankingExtract = lazy(
  () => import("./routes/Reports/StoreGroup/StoreGroupRankingExtract")
)
const GmadCampaignsExtract = lazy(
  () => import("./routes/Reports/StoreGroup/CampaignsExtract")
)
const GmadSalesmanProgram = lazy(
  () => import("./routes/Reports/StoreGroup/SalesmanProgram")
)
const GoalTracking = lazy(() => import("./routes/Reports/Sales/GoalTracking"))
const InventoryReturns = lazy(
  () => import("./routes/Reports/Inventory/InventoryReturns")
)
const Login = lazy(() => import("./routes/Login"))
const NotFound = lazy(() => import("./routes/NotFound"))
const RenderPowerBI = lazy(() => import("./routes/Reports/RenderPowerBI"))
const CampaignExtract = lazy(
  () => import("./routes/Reports/Sales/CampaignExtract")
)
const Sales1kLiters = lazy(() => import("./routes/Reports/Sales/Sales1kLiters"))
const IndicatorBoard = lazy(
  () => import("./routes/Reports/Sales/IndicatorBoard")
)
const Commissions = lazy(() => import("./routes/Reports/Sales/Commissions"))
const FuelDiscount = lazy(() => import("./routes/Reports/Sales/FuelDiscount"))
const FuelDiscountNew = lazy(
  () => import("./routes/Reports/Consiliation/SalesConsiliation")
)
const FuelTargets = lazy(() => import("./routes/Reports/Sales/FuelTargets"))
const StoreGroup = lazy(() => import("./routes/Reports/StoreGroup/StoreGroup"))
const SelloutItems = lazy(
  () => import("./routes/Reports/StoreGroup/SelloutItems")
)
const GroupInventory = lazy(
  () => import("./routes/Reports/StoreGroup/GroupInventory")
)
const FleetExpenses = lazy(
  () => import("./routes/Reports/Logistic/FleetExpenses")
)
const UserProfile = lazy(() => import("./routes/UserProfile"))
const PaymentSales = lazy(() => import("./routes/Reports/Sales/PaymentSales"))
const HelpCenter = lazy(() => import("./routes/HelpCenter"))
const Logs = lazy(() => import("./routes/Logs"))
const CheckIn = lazy(() => import("./routes/ComplementaryApps/CheckIn"))
const HelpCenterInitial = lazy(
  () => import("./routes/HelpCenter/HelpCenterInitial")
)
const HelpCenterFaq = lazy(() => import("./routes/HelpCenter/HelpCenterFaq"))
const HelpCenterVideoListByPlatform = lazy(
  () => import("./routes/HelpCenter/HelpCenterVideoListByPlatform")
)
const HelpCenterVideoListByCategory = lazy(
  () => import("./routes/HelpCenter/HelpCenterVideoListByCategory")
)
const HelpCenterVideo = lazy(
  () => import("./routes/HelpCenter/HelpCenterVideo")
)
const FinancialAccountsControl = lazy(
  () => import("./routes/Reports/Financial/FinancialAccountsControl")
)
const AccountsControlCredit = lazy(
  () => import("./routes/Reports/Financial/AccountsControlCredit")
)
const AccountsControlExpense = lazy(
  () => import("./routes/Reports/Financial/AccountsControlExpense")
)
const AccountsControlReceive = lazy(
  () => import("./routes/Reports/Financial/AccountsControlReceive")
)
const Carriers = lazy(() => import("./routes/Reports/Financial/Carriers"))
const CashFlow = lazy(
  () => import("./routes/Reports/FinancialSupervisor/CashFlow")
)
const Taxes = lazy(() => import("./routes/Reports/FinancialSupervisor/Taxes"))
const PortfolioManagement = lazy(
  () => import("./routes/Reports/Sales/PortfolioManagement")
)
const StoreGroupCampaings = lazy(
  () => import("./routes/Reports/StoreGroup/StoreGroupCampaings")
)
const SalesAnalysisStream = lazy(
  () => import("./routes/Reports/Sales/SalesAnalysisStream")
)
const ScreenTracking = lazy(
  () => import("./routes/Reports/CustomerSuccess/ScreenTracking")
)
const VisitTracking = lazy(
  () => import("./routes/Reports/CheckIn/VisitTracking")
)
const ErrorTracking = lazy(
  () => import("./routes/Reports/CustomerSuccess/ErrorTracking")
)
const PurchaseOrder = lazy(
  () => import("./routes/Reports/Purchase/PurchaseOrder")
)
const PurchaseAnalysis = lazy(
  () => import("./routes/Reports/Purchase/PurchaseAnalysis")
)
const GasStationComparsion = lazy(
  () => import("./routes/Reports/Board/GasStationComparsion")
)

export default function App() {
  const posthog = usePostHog()

  const storedUser = useUserStore((store) => store.user)
  const storedCompany = useCompanyStore((store) => store.company.nome_fantasia)
  const storedCompanyId = useCompanyStore((store) => store.company.id)

  useEffect(() => {
    posthog.identify(storedUser?.id.toString(), {
      email: storedUser?.email,
      name: storedUser?.first_name,
      company: storedCompany
    })
    posthog.group(storedCompany, storedUser?.group || "", {
      id: storedCompanyId,
      nameCompany: storedCompany,
      userId: storedUser?.id.toString(),
      email: storedUser?.email,
      nameUser: storedUser?.first_name
    })
    posthog.capture("$pageview", {
      userInfo: {
        id: storedUser?.id.toString(),
        email: storedUser?.email,
        firstName: storedUser?.first_name,
        lastName: storedUser?.last_name,
        company: storedCompany
      }
    })
  })

  return (
    <>
      <VersionDisplay />

      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/404" />} />
          <Route path="/404" element={<NotFound />} />
          <Route
            element={
              <RequireAuth>
                <Layout />
              </RequireAuth>
            }
          >
            <Route path="/" element={<Dashboard />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/logs" element={<Logs />} />
            <Route path="/check-in" element={<CheckIn />} />
            <Route path="/help-center" element={<HelpCenter />}>
              <Route path="/help-center" element={<HelpCenterInitial />} />
              <Route path="/help-center/faq" element={<HelpCenterFaq />} />
              <Route
                path="/help-center/plataforma/:platform"
                element={<HelpCenterVideoListByPlatform />}
              />
              <Route
                path="/help-center/categoria/:category"
                element={<HelpCenterVideoListByCategory />}
              />
              <Route
                path="/help-center/video/:platform/:category/:video"
                element={<HelpCenterVideo />}
              />
            </Route>
            <Route path="/administrative" element={<AdministrativeArea />}>
              <Route
                path="/administrative/company"
                element={<AdministrativeAreaCompanyInformation />}
              />
              <Route
                path="/administrative/campaigns"
                element={<AdministrativeAreaCampaigns />}
              />
              <Route
                path="/administrative/campaigns/:campaignId"
                element={<AdministrativeAreaCampaignsDetails />}
              />
              <Route
                path="/administrative/commissions"
                element={<AdministrativeAreaCommissions />}
              />
              <Route
                path="/administrative/goals/sales"
                element={<AdministrativeAreaGoalsSales />}
              />
              <Route
                path="/administrative/goals/sales/:goalId"
                element={<AdministrativeAreaGoalsSalesDetails />}
              />
              <Route
                path="/administrative/goals/purchases"
                element={<AdminsitrativeAreaGoalsPurchases />}
              />
              <Route
                path="/administrative/indicators"
                element={<AdministrativeAreaIndicators />}
              />
              <Route
                path="/administrative/permissions"
                element={<AdministrativeAreaPermissions />}
              />
              <Route
                path="/administrative/parameterization/potencial"
                element={<AdministrativeAreaParametrizationPotential />}
              />
              <Route
                path="/administrative/parameterization/financial"
                element={<AdministrativeAreaParametrizationFinancial />}
              />
              <Route
                path="/administrative/parameterization/checkin"
                element={<AdministrativeAreaParametrizationCheckin />}
              />
              <Route
                path="/administrative/parameterization/checkin/bulk-scheduling/create"
                element={
                  <AdministrativeAreaParametrizationCheckinBulkSchedulingCreate />
                }
              />
              <Route
                path="/administrative/parameterization/sellers"
                element={<AdministrativeAreaParametrizationSellers />}
              />

              <Route
                path="/administrative/parameterization/market-classification"
                element={
                  <AdministrativeAreaParametrizationMarketClassificationTabs />
                }
              />
              <Route
                path="/administrative/parameterization/returns"
                element={<AdministrativeAreaParametrizationReturns />}
              />
              <Route
                path="/administrative/parameterization/portfolio"
                element={<AdministrativeAreaParametrizationPortfolio />}
              />
              <Route
                path="/administrative/parameterization/calendar"
                element={<AdministrativeAreaParametrizationCalendar />}
              />
              <Route
                path="/administrative/parameterization/inventory"
                element={<AdministrativeAreaParametrizationInventory />}
              />
              <Route
                path="/administrative/users"
                element={<AdministrativeAreaUsers />}
              />
            </Route>
            <Route
              path="/reports/a1e74b77-9a60-4f43-b8e1-f3e9a3a24f4e"
              element={<AmericanaReceivableMonitoring />}
            />
            <Route
              path="/reports/6ee892ad-399c-40fd-8309-3a01196df6ff"
              element={<ReceivableMonitoring />}
            />
            <Route
              path="/reports/5687d57d-302f-4c02-a258-449204138723"
              element={<DRE />}
            />
            <Route
              path="/reports/aee87367-ca25-4e12-b474-d5b5ec034466"
              element={<TiresGathering />}
            />
            <Route
              path="/reports/d4b20e07-f287-44ba-ae4c-57d06b2576a6"
              element={<LogisticChecklist />}
            />
            <Route
              path="/reports/86ee6707-da80-4d97-9612-ac6c70438b21"
              element={<OperationsChecklist />}
            />
            <Route
              path="/reports/15d6d673-ad2e-4a9b-8695-5287549b457b"
              element={<BudgetAnalysis />}
            />
            <Route
              path="/reports/681828f6-ea1a-4563-9e5f-8f775f681c7e"
              element={<SalesAnalysisStream />}
            />
            <Route
              path="/reports/396ecb2c-fd15-4721-ad87-1c3a09fa962b"
              element={<PortfolioManagement />}
            />
            <Route
              path="/reports/70255859-5c33-45fd-9759-37a7da516f5a"
              element={<MarketClassification />}
            />
            <Route
              path="/reports/001868fd-1b45-4799-aa7f-69e895a82ab7"
              element={<SalesByLocation />}
            />
            <Route
              path="/reports/0b997f7e-cd9b-49b8-a1cf-31a05ea034b9"
              element={<Commissions />}
            />
            <Route
              path="/reports/26b1781a-1832-4dd7-b8f0-cdeab25f31bd"
              element={<CampaignExtract />}
            />
            <Route
              path="/reports/c08e0ff5-7681-4a12-bc7a-9b5c8cc5e3b2"
              element={<MahleAward />}
            />
            <Route
              path="/reports/1a31c195-e97c-4f74-b97b-17ff976f0f9f"
              element={<PaymentSales />}
            />
            <Route
              path="/reports/b508e5bd-0f8b-415a-93b6-e9646e3cc34d"
              element={<ExchangeCenter />}
            />
            <Route
              path="/reports/537169bb-f6ca-495b-8bd9-40376a989abf"
              element={<InventoryAdjustment />}
            />
            <Route
              path="/reports/5e7a8a31-5678-41f9-a8d7-2f02cfcf9a1b"
              element={<PurchaseCenter />}
            />
            <Route
              path="/reports/a4e6881c-aa4c-4dfe-8f6e-8269c2d79e0f"
              element={<FinancialAccountsControl />}
            />
            <Route
              path="/reports/988cf391-6171-4171-aca2-78496c0d214c"
              element={<AccountsControlCredit />}
            />
            <Route
              path="/reports/0279d5c3-40d5-4bdf-b04b-6b875e9091ef"
              element={<AccountsControlExpense />}
            />
            <Route
              path="/reports/f90b8605-be90-4186-84c0-9c6cad5b97f7"
              element={<AccountsControlReceive />}
            />
            <Route
              path="/reports/20a610e3-655e-4ad1-b244-323959d1969c"
              element={<Carriers />}
            />
            <Route
              path="/reports/aee2689a-6edc-4576-a8bd-4b8c2d961dcb"
              element={<CashFlow />}
            />
            <Route
              path="/reports/7ddb5ab0-52b5-4177-8a95-5c52eb3e1e02"
              element={<Taxes />}
            />
            <Route
              path="/reports/8a9940d4-3e4f-49e1-8c9b-6f1818e0b7aa"
              element={<CustomerFrequency />}
            />
            <Route
              path="/reports/fa537ec1-2a94-4b0e-a3b1-7b5f9c68d1c9"
              element={<EvolutionCurve />}
            />
            <Route
              path="/reports/2cdac5e6-b5e3-4576-b8ea-fae7e2d987c1"
              element={<InventoryReturns />}
            />
            <Route
              path="/reports/3d4b876f-7a66-4e34-9b8c-3ec69dfb8e96"
              element={<CurrentInventory />}
            />
            <Route
              path="/reports/b7e19cb9-e9b1-4d31-89c1-265a2d6f7f38"
              element={<InventoryMovement />}
            />
            <Route
              path="/reports/4a5c5f2d-59e4-4f74-b8b0-216e3d23d5d2"
              element={<GlobalInventory />}
            />
            <Route
              path="/reports/d0e7a952-ec7e-408c-97f8-67d2168bede0"
              element={<TanksControl />}
            />
            <Route
              path="/reports/d2a27a36-b2de-45c0-8c8b-3cd0f53bf2ef"
              element={<GmadCampaign />}
            />
            <Route
              path="/reports/adcaa8ac-e66d-46b4-9110-5d7df8065d75"
              element={<BrandGoals />}
            />
            <Route
              path="/reports/f6a35a9e-af3f-47a2-b2b8-78c2ed2a384c"
              element={<CustomerOneDepartment />}
            />
            <Route
              path="/reports/189eb4ea-57bb-4d43-bb5d-34408b391118"
              element={<StoreGroupCampaings />}
            />
            <Route
              path="/reports/8e9b1f1a-6d5a-4c75-b3e2-3d8272d9a4a7"
              element={<StoreGroup />}
            />
            <Route
              path="/reports/5b2903b8-0d7a-4462-a469-cedca3d7d7e5"
              element={<SelloutItems />}
            />
            <Route
              path="/reports/e93535ca-fb0c-4c81-af41-b38bb2467325"
              element={<GroupInventory />}
            />
            <Route
              path="/reports/2b7a3f51-77e8-467d-a14b-4c594f22c582"
              element={<GmadSalesmanProgram />}
            />
            <Route
              path="/reports/8029bc4a-d21c-48bf-ba5c-59a2ad596bb4"
              element={<GmadRankingExtract />}
            />
            <Route
              path="/reports/bf9bfeb9-66e5-4baf-81e1-f940d390bd71"
              element={<GmadCampaignsExtract />}
            />
            <Route
              path="/reports/ddbb695d-9c4b-44e9-9b8e-c1d5f8e0011b"
              element={<GoalTracking />}
            />
            <Route
              path="/reports/5b78c3ef-ceb4-463d-b474-c569c522d3ca"
              element={<Sales1kLiters />}
            />
            <Route
              path="/reports/2ecc3a8a-c2bb-4ea3-b95d-4eda64264eb1"
              element={<IndicatorBoard />}
            />
            <Route
              path="/reports/dab71560-adf5-4ab9-a208-56a269cfd55a"
              element={<GasStationComparsion />}
            />
            <Route
              path="/reports/d2c2a4df-41b5-4d52-9329-c2346a12f8a3"
              element={<FuelDiscount />}
            />
            <Route
              path="/reports/733a4101-1a96-4e29-a0a2-962deee90388"
              element={<FuelDiscountNew />}
            />
            <Route
              path="/reports/c5cb657c-c0fb-4e6c-ad47-f7edb5a275e2"
              element={<FuelTargets />}
            />
            <Route
              path="/reports/:datasetId/:reportId/:id/:reportName"
              element={<RenderPowerBI />}
            />
            <Route
              path="/reports/8ce8c829-04b6-4846-8eb8-c49be8fb09e9"
              element={<FleetExpenses />}
            />
            <Route
              path="/reports/e618d82a-188a-4ca8-b07f-a83ad9febf50"
              element={<PurchaseAnalysis />}
            />
            <Route
              path="/reports/9e37a54f-0be3-4330-b5cd-990734ac6401"
              element={<VisitTracking />}
            />
            <Route
              path="/reports/e6df4509-2650-46e2-9e0f-f317e2856c84"
              element={<ErrorTracking />}
            />
            <Route
              path="/reports/3417359b-3a00-47bf-83fe-fa82e82b889f"
              element={<PurchaseOrder />}
            />
            <Route
              path="/reports/cace867c-00bf-4081-9a53-f11585320718"
              element={<ScreenTracking />}
            />
          </Route>
        </Routes>
      </Suspense>
    </>
  )
}

function Layout() {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()
  const hasReport = useHasReport(pathname)

  useEffect(() => {
    if (!hasReport) {
      navigate("/")
    }
  }, [hasReport, navigate, pathname])

  return (
    <>
      {!search.includes("presentation") && <Navbar />}
      <ErrorBoundary
        FallbackComponent={({ resetErrorBoundary }) => (
          <ErrorComponent handleReload={resetErrorBoundary} showSuportMessage />
        )}
      >
        <Outlet />
      </ErrorBoundary>
    </>
  )
}
