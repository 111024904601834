import {
  CancelCheckInActivityParamsProps,
  CancelCheckInActivityQueryProps,
  CheckInActivityProps,
  CheckInAddTagProps,
  CheckInAddTagsParamsProps,
  CheckInCancellationReasonsProps,
  CheckInCreateParamsProps,
  CheckInCreateProps,
  CheckInCustomerFile,
  CheckInDeleteTagProps,
  CheckInEditParamsProps,
  CheckInEditProps,
  CheckInGetCustomerHistoryProps,
  CheckInLocationValues,
  CheckInOperation,
  CheckInOperationAdmin,
  CheckinParameterizationEndDistanceProps,
  CheckInResume,
  CheckInTag,
  CreateCheckInActivityWithProspectParams,
  CreateCheckInActivityWithProspectProps,
  CreateCheckInTagsParamsProps,
  CreateCheckInTagsQueryProps,
  UpdateCheckInTagsParamsProps,
  CreateProspectParamsProps,
  CreateProspectProps,
  CreateVisitFileQueryProps,
  CreateVisitObservationParamsProps,
  CreateVisitObservationQueryProps,
  CustomerAddress,
  DeleteVisitFileQueryProps,
  DeleteVisitObservationQueryProps,
  EditVisitObservationParamsProps,
  EditVisitObservationQueryProps,
  ProspectProps,
  VisitObservation,
  UpdateCheckInTagMutationData
} from "@/types/complementaryApps/checkIn"
import { MapCoordsProps } from "@/types/reports/sales/salesByLocation"
import MapboxGeocoding from "@mapbox/mapbox-sdk/services/geocoding"
import { useMutation, useQuery } from "react-query"
import { getApi } from ".."

export const useQueryGetCustomerAddress = (
  companyId: number,
  customerId: number,
  options: object
) =>
  useQuery<CustomerAddress[]>(
    ["queryGetCustomerAddress", companyId, customerId],
    async () => {
      const api = await getApi()

      const params = {
        report: "get_endereco",
        companyId,
        skPessoa: customerId
      }

      return api
        .post(`/mobile/report/query_v2`, params)
        .then((result) => result.data.dados)
    },
    options
  )

export const useMutationCreateVisitObservation = (options: object) =>
  useMutation(
    async ({
      companyId,
      skVisita,
      observacao
    }: CreateVisitObservationQueryProps) => {
      const api = await getApi()

      const params: CreateVisitObservationParamsProps = {
        skVisita,
        observacao
      }

      return api
        .post(`/checkin/visitobservation/${companyId}/`, params)
        .then((result) => result.data)
    },
    options
  )

export const useQueryGetVisitObservations = (
  companyId,
  skVisita,
  options: object
) =>
  useQuery<VisitObservation[]>(
    ["queryGetVisitObservations", companyId, skVisita],
    async () => {
      const api = await getApi()

      return api
        .get(`checkin/visitobservation/${companyId}/${skVisita}/`)
        .then((result) => result.data)
    },
    options
  )

export const useMutationEditVisitObservation = (options: object) =>
  useMutation(
    async ({
      companyId,
      skVisita,
      observacao,
      observationId
    }: EditVisitObservationQueryProps) => {
      const api = await getApi()

      const params: EditVisitObservationParamsProps = {
        observacao
      }

      return api
        .put(
          `/checkin/visitobservation/${companyId}/${skVisita}/${observationId}/`,
          params
        )
        .then((result) => result.data)
    },
    options
  )

export const useMutationDeleteVisitObservation = (options: object) =>
  useMutation(
    async ({
      companyId,
      skVisita,
      observationId
    }: DeleteVisitObservationQueryProps) => {
      const api = await getApi()

      return api
        .delete(
          `/checkin/visitobservation/${companyId}/${skVisita}/${observationId}/`
        )
        .then((result) => result.data)
    },
    options
  )

export const useMutationDeleteVisitFile = (options: object) =>
  useMutation(
    async ({ companyId, skVisita, fileId }: DeleteVisitFileQueryProps) => {
      const api = await getApi()

      return api
        .delete(`/checkin/visitarchive/${companyId}/${skVisita}/${fileId}/`)
        .then((result) => result.data)
    },
    options
  )

export const useMutationCreateCheckInActivity = (options: object) =>
  useMutation(
    async ({
      companyId,
      skPessoa,
      skTipoOperacao,
      skEndereco,
      dtAgd,
      skUsuario,
      latitude,
      longitude,
      descricao,
      participantes,
      tags
    }: CheckInCreateProps) => {
      const api = await getApi()

      const params: CheckInCreateParamsProps = {
        skPessoa,
        skTipoOperacao,
        dtAgd,
        skEndereco,
        skUsuario,
        latitude,
        longitude,
        descricao,
        participantes,
        tags
      }

      return api
        .post(`/checkin/visit/${companyId}/`, params)
        .then((result) => result.data.dados)
    },
    options
  )

export const useMutationCreateCheckInActivityWithProspect = (options: object) =>
  useMutation(
    async ({
      companyId,
      skProspect,
      skTipoOperacao,
      dtAgd,
      skUsuario,
      latitude,
      longitude,
      descricao,
      participantes,
      tags,
      endereco,
      bairro,
      cidade,
      uf,
      estado,
      cep
    }: CreateCheckInActivityWithProspectProps) => {
      const api = await getApi()

      const params: CreateCheckInActivityWithProspectParams = {
        skProspect,
        skTipoOperacao,
        dtAgd,
        skUsuario,
        latitude,
        longitude,
        descricao,
        participantes,
        tags,
        endereco,
        bairro,
        cidade,
        uf,
        estado,
        cep,
        skEndereco: null
      }

      return api
        .post(`/checkin/visit/${companyId}/`, params)
        .then((result) => result.data.dados)
    },
    options
  )

export const useMutationCreateProspect = (options: object) =>
  useMutation(
    async ({
      companyId,
      name,
      phone,
      address,
      neighborhood,
      city,
      state,
      zipCode,
      observation
    }: CreateProspectProps) => {
      const api = await getApi()

      const params: CreateProspectParamsProps = {
        nome: name,
        endereco: address,
        bairro: neighborhood,
        cidade: city,
        uf: state,
        cep: zipCode
      }

      if (phone) {
        params.telefone = phone
      }

      if (observation) {
        params.observacao = observation
      }

      return api
        .post(`/checkin/prospects/${companyId}/`, params)
        .then((result) => result.data)
    },
    options
  )

export const useMutationEditCheckInActivity = (options: object) =>
  useMutation(
    async ({ companyId, visitId, dtIni, dtFim }: CheckInEditProps) => {
      const api = await getApi()

      const params: CheckInEditParamsProps = {
        dtIni,
        dtFim
      }

      return api
        .put(`/checkin/visit/${companyId}/${visitId}`, params)
        .then((result) => result.data.dados)
    },
    options
  )

export const useMutationEditCheckInActivityAddTag = (options: object) =>
  useMutation(async ({ companyId, visitId, tagId }: CheckInAddTagProps) => {
    const api = await getApi()

    const params: CheckInAddTagsParamsProps = {
      skVisita: visitId,
      skVisitaTag: tagId
    }

    return api
      .post(`/checkin/visittag/${companyId}/`, params)
      .then((result) => result.data.dados)
  }, options)

export const useMutationEditCheckInActivityDeleteTag = (options: object) =>
  useMutation(async ({ companyId, visitId, tagId }: CheckInDeleteTagProps) => {
    const api = await getApi()

    return api
      .delete(`/checkin/visittag/${companyId}/${visitId}/${tagId}/`)
      .then((result) => result.data.dados)
  }, options)

export const useQueryGetCheckInActivitiesPanel = (
  companyId: number,
  dtStart: string,
  dtEnd: string,
  skPessoa: number,
  skSupervisor: number,
  skVendedor: number,
  cidade: string,
  estado: string,
  regiao: string,
  hideFinished: boolean,
  options: object
) =>
  useQuery<CheckInActivityProps[]>(
    [
      "queryGetCheckInActivities",
      companyId,
      dtStart,
      dtEnd,
      skPessoa,
      skSupervisor,
      skVendedor,
      cidade,
      estado,
      regiao,
      hideFinished
    ],
    async () => {
      const api = await getApi()

      let baseUrl = `/checkin/visit/${companyId}/?dtAgdStart=${dtStart}&dtAgdEnd=${dtEnd}&showDelayed=true&showStarted=true`

      if (hideFinished) {
        baseUrl += `&hideFinished=${hideFinished}`
      }

      if (skPessoa !== -99) {
        baseUrl += `&skPessoa=${skPessoa}`
      }

      if (skSupervisor !== -99) {
        baseUrl += `&skSupervisor=${skSupervisor}`
      }

      if (skVendedor !== -99) {
        baseUrl += `&skUsuario=${skVendedor}`
      }

      if (cidade !== "") {
        baseUrl += `&cidade=${cidade.split("/", 1)}`
      }

      if (estado !== "") {
        baseUrl += `&estado=${estado}`
      }

      if (regiao !== "") {
        baseUrl += `&regiao=${regiao}`
      }

      return api.get(baseUrl).then((result) => result.data)
    },
    options
  )

export const useQueryGetCheckInActivities = (
  companyId: number,
  dtStart: string,
  dtEnd: string,
  skPessoa: number,
  skSupervisor: number,
  skVendedor: number,
  cidade: string,
  bairro: string,
  regiao: string,
  estado: string,
  skTipoOperacao: number,
  status: string,
  hideFinished: boolean,
  options: object
) =>
  useQuery<CheckInActivityProps[]>(
    [
      "queryGetCheckInActivities",
      companyId,
      dtStart,
      dtEnd,
      skPessoa,
      skSupervisor,
      skVendedor,
      cidade,
      bairro,
      regiao,
      estado,
      skTipoOperacao,
      status,
      hideFinished
    ],
    async () => {
      const api = await getApi()

      let baseUrl = `/checkin/visit/${companyId}/?dtAgdStart=${dtStart}&dtAgdEnd=${dtEnd}`

      if (hideFinished) {
        baseUrl += `&hideFinished=${hideFinished}`
      }

      if (!status) {
        baseUrl += "&showDelayed=true&showCanceled=true"
      }

      if (skPessoa !== -99) {
        baseUrl += `&skPessoa=${skPessoa}`
      }

      if (skSupervisor !== -99) {
        baseUrl += `&skSupervisor=${skSupervisor}`
      }

      if (skVendedor !== -99) {
        baseUrl += `&skUsuario=${skVendedor}`
      }

      if (cidade !== "") {
        baseUrl += `&cidade=${cidade}`
      }

      if (bairro !== "") {
        baseUrl += `&bairro=${bairro}`
      }

      if (regiao !== "") {
        baseUrl += `&regiao=${regiao}`
      }

      if (estado !== "") {
        baseUrl += `&estado=${estado}`
      }

      if (skTipoOperacao !== -99) {
        baseUrl += `&skTipoOperacao=${skTipoOperacao}`
      }

      if (status !== "") {
        baseUrl += `&status=${status}`
      }

      return api.get(baseUrl).then((result) => result.data)
    },
    options
  )

export const useQueryGetCheckInActivitiesQueryV2 = (
  companyId: number,
  dtStart: string,
  dtEnd: string,
  skPessoa: number | null,
  skSupervisor: number | null,
  skVendedor: number | null,
  cidade: string,
  bairro: string,
  regiao: string,
  estado: string,
  skTipoOperacao: number | null,
  status: string,
  hideFinished: boolean,
  options: object
) =>
  useQuery<CheckInActivityProps[]>(
    [
      "queryGetCheckInActivitiesQueryV2",
      companyId,
      dtStart,
      dtEnd,
      skPessoa,
      skSupervisor,
      skVendedor,
      cidade,
      bairro,
      regiao,
      estado,
      skTipoOperacao,
      status,
      hideFinished
    ],
    async () => {
      const api = await getApi()
      const params: {
        companyId: number
        report: string
        dtIni: string
        dtFim: string
        skPessoa?: number
        skSupervisor?: number
        skVendedor?: number
        skTipoOperacao?: number
        cidade?: string
        bairro?: string
        regiao?: string
        estado?: string
        status?: string
      } = {
        companyId,
        report: "rel_acompanhamento_visita",
        dtIni: dtStart,
        dtFim: dtEnd
      }

      if (status) {
        params.status = status
      }

      if (skPessoa) {
        params.skPessoa = skPessoa
      }

      if (skSupervisor) {
        params.skSupervisor = skSupervisor
      }

      if (skVendedor) {
        params.skVendedor = skVendedor
      }

      if (cidade) {
        params.cidade = cidade
      }

      if (bairro) {
        params.bairro = bairro
      }

      if (regiao) {
        params.regiao = regiao
      }

      if (estado) {
        params.estado = estado
      }

      if (skTipoOperacao) {
        params.skTipoOperacao = skTipoOperacao
      }

      return api
        .post(`/mobile/report/query_v2`, params)
        .then((result) => result.data.dados)
    },
    options
  )

export const useQueryGetCheckInActivityDetails = (
  companyId: number,
  visitId: number,
  options: object
) =>
  useQuery<CheckInActivityProps>(
    ["queryGetCheckInActivityDetails", companyId, visitId],
    async () => {
      const api = await getApi()

      return api
        .get(`/checkin/visit/${companyId}/${visitId}/`)
        .then((result) => result.data[0])
    },
    options
  )

export const useQueryGetCheckInActivityTags = (
  companyId: number,
  visitId: number,
  options: object
) =>
  useQuery<CheckInTag[]>(
    ["queryGetCheckInActivityTags", companyId, visitId],
    async () => {
      const api = await getApi()

      return api
        .get(`/checkin/visittag/${companyId}?skVisita=${visitId}`)
        .then((result) => result.data)
    },
    options
  )

export const useQueryGetCheckInOperationsAdmin = (
  companyId: number,
  options: object
) =>
  useQuery<CheckInOperationAdmin[]>(
    ["queryGetCheckInOperationsAdmin", companyId],
    async () => {
      const api = await getApi()

      return api
        .get(`/checkin/visitoperation-admin`)
        .then((result) => result.data.results)
    },
    options
  )

export const useMutationEditCheckInOperationsAdmin = (options: object) =>
  useMutation(
    async ({
      operation,
      companyIds
    }: {
      operation: CheckInOperationAdmin
      companyIds: number[]
    }) => {
      const api = await getApi()

      const params = {
        company_ids: companyIds
      }

      return api
        .patch(`/checkin/visitoperation-admin/${operation.id}/`, params)
        .then((result) => result.data)
    },
    options
  )

export const useQueryGetCheckInOperations = (
  companyId: number,
  options: object
) =>
  useQuery<CheckInOperation[]>(
    ["queryGetCheckInOperations", companyId],
    async () => {
      const api = await getApi()

      return api
        .get(`/checkin/visitoperation/${companyId}`)
        .then((result) => result.data)
    },
    options
  )

export const useQueryGetAddressGeocodingInformation = (
  address: string,
  options: object
) => {
  const geocodingClient = MapboxGeocoding({
    accessToken: import.meta.env.VITE_MAPBOX_TOKEN
  })

  return useQuery<MapCoordsProps>(
    ["queryGetAddressGeocodingInformation", address],
    async () => {
      const response = await geocodingClient
        .forwardGeocode({
          query: address,
          limit: 1
        })
        .send()

      if (response?.body?.features?.length) {
        const feature: [number, number] = response.body.features[0].center
        const query: string[] = response.body.query
        return { feature, query } as MapCoordsProps
      }

      throw new Error("No results found")
    },
    options
  )
}

export const useQueryGetCheckInLocationValues = (
  companyId: number,
  dtIni: string,
  dtEnd: string,
  options: object
) =>
  useQuery<CheckInLocationValues>(
    ["queryGetCheckInLocationValues", companyId],
    async () => {
      const api = await getApi()

      return api
        .get(
          `/checkin/saleslocation/${companyId}/?dtStart=${dtIni}&dtEnd=${dtEnd}`
        )
        .then((result) => result.data)
    },
    options
  )

export const useQueryGetCheckInResumeValues = (
  companyId: number,
  dtIni: string,
  dtEnd: string,
  options: object
) =>
  useQuery<CheckInResume>(
    ["queryGetCheckInResumeValues", companyId],
    async () => {
      const api = await getApi()

      return api
        .get(`/checkin/resume/${companyId}/?dtStart=${dtIni}&dtEnd=${dtEnd}`)
        .then((result) => result.data)
    },
    options
  )

export const useMutationCreateVisitFile = (options: object = {}) =>
  useMutation(
    async ({ companyId, skVisita, arquivos }: CreateVisitFileQueryProps) => {
      const api = await getApi()

      const formData = new FormData()
      formData.append("skVisita", skVisita.toString())

      arquivos.forEach((arquivoObj, index) => {
        formData.append(`arquivos[${index}]arquivo`, arquivoObj.arquivo)
        if (arquivoObj.descricao) {
          formData.append(`arquivos[${index}]descricao`, arquivoObj.descricao)
        }
      })

      return api
        .post(`/checkin/visitarchive/${companyId}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((result) => result.data)
    },
    options
  )

export const useQueryGetCheckInTags = (companyId: number, options: object) =>
  useQuery<CheckInTag[]>(
    ["queryGetCheckInTags", companyId],
    async () => {
      const api = await getApi()

      return api.get(`/checkin/tag/${companyId}`).then((result) => result.data)
    },
    options
  )

export const useMutationCreateCheckInTags = (options: object) =>
  useMutation(
    async ({ companyId, tag, description }: CreateCheckInTagsQueryProps) => {
      const api = await getApi()

      const params: CreateCheckInTagsParamsProps = {
        tag,
        descricao: description
      }

      return api
        .post(`/checkin/tag/${companyId}/`, params)
        .then((result) => result.data)
    },
    options
  )

export const useMutationUpdateCheckInTags = (options: object) =>
  useMutation(
    async ({
      companyId,
      id,
      tag,
      description
    }: UpdateCheckInTagMutationData) => {
      if (!companyId || !id) {
        throw new Error("Company ID and Tag ID are required")
      }

      const api = await getApi()

      const params: UpdateCheckInTagsParamsProps = {}

      if (tag) params.tag = tag
      if (description) params.descricao = description

      return api
        .patch(`/checkin/tag/${companyId}/${id}/`, params)
        .then((result) => result.data)
        .catch((error) => {
          throw error
        })
    },
    options
  )

export const useMutationInactivateCompanyTag = (options: object) =>
  useMutation(
    async ({ companyId, tagId }: { companyId: number; tagId: number }) => {
      const api = await getApi()

      return api
        .delete(`/checkin/tag/${companyId}/${tagId}`)
        .then((result) => result.data)
    },
    options
  )

export const useMutationLinkProspects = (options: object) =>
  useMutation(
    async ({
      companyId,
      prospectId,
      skPessoa,
      skEndereco,
      latitude,
      longitude
    }: {
      companyId: number
      prospectId: number
      skPessoa: number
      skEndereco: number
      latitude: number
      longitude: number
    }) => {
      const api = await getApi()

      return api
        .put(`/checkin/linkprospect/${companyId}/${prospectId}`, {
          skPessoa,
          skEndereco,
          latitude,
          longitude
        })
        .then((result) => result.data)
    },
    options
  )

export const useMutationActivateCompanyTag = (options: object) =>
  useMutation(
    async ({ companyId, tagId }: { companyId: number; tagId: number }) => {
      const api = await getApi()

      return api
        .put(`/checkin/tag/${companyId}/${tagId}/restore`)
        .then((result) => result.data)
    },
    options
  )

export const useQueryGetCheckInPropspects = (
  companyId: number,
  options: object
) =>
  useQuery<ProspectProps[] | null>(
    ["queryGetCheckInPropspects", companyId],
    async () => {
      const api = await getApi()

      const result = await api
        .get(`checkin/prospects/${companyId}`)
        .then((result) => result)

      if (result.status == 204) {
        return []
      }

      return result.data
    },
    options
  )

export const useQueryGetCheckInCustomerFile = (
  companyId: number,
  customerId: number,
  options: object
) =>
  useQuery<CheckInCustomerFile | null>(
    ["queryGetCheckInTags", companyId, customerId],
    async () => {
      const api = await getApi()

      const result = await api
        .get(`checkin/financialinfo/${companyId}/${customerId}`)
        .then((result) => result)

      if (result.status == 204) {
        return null
      }

      return result.data
    },
    options
  )

export const useQueryCheckInCancellationReasons = (options: object) =>
  useQuery<CheckInCancellationReasonsProps[]>(
    ["queryCheckInCancellationReasons"],
    async () => {
      const api = await getApi()

      return api.get("checkin/visitcancellation/").then((result) => result.data)
    },
    options
  )

export const useMutationCancelCheckInActivity = (options: object) =>
  useMutation(
    async ({
      companyId,
      visitId,
      skMotivoCanc
    }: CancelCheckInActivityQueryProps) => {
      const api = await getApi()

      const params: CancelCheckInActivityParamsProps = {
        skMotivoCanc
      }

      return api
        .put(`/checkin/visitcancellation/${companyId}/${visitId}`, params)
        .then((result) => result.data)
    },
    options
  )

export const useQueryCheckInGetCustomerHistory = (
  companyId: number,
  customerId: number,
  startDate: string,
  endDate: string,
  options: object
) =>
  useQuery<CheckInGetCustomerHistoryProps>(
    ["queryCheckInGetCustomerHistory"],
    async () => {
      const api = await getApi()

      return api
        .get(
          `checkin/clienthistorical/${companyId}/${customerId}/?dtStart=${startDate}&dtEnd=${endDate}`
        )
        .then((result) => result.data)
    },
    options
  )

export const useQueryGetCitiesWithVisit = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetCitiesWithVisit", companyId],
    async () => {
      const api = await getApi()
      return await api
        .post("/mobile/report/query_v2", {
          companyId,
          report: "get_checkin_cities"
        })
        .then((response) => response.data.dados)
    },
    options
  )
}

export const useQueryGetNeighborhoodWithVisit = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetNeighborhoodWithVisit", companyId],
    async () => {
      const api = await getApi()
      return await api
        .post("/mobile/report/query_v2", {
          companyId,
          report: "get_checkin_neighborhoods"
        })
        .then((response) => response.data.dados)
    },
    options
  )
}

export const useQueryGetStatesWithVisit = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetStatesWithVisit", companyId],
    async () => {
      const api = await getApi()
      return await api
        .post("/mobile/report/query_v2", {
          companyId,
          report: "get_checkin_states"
        })
        .then((response) => response.data.dados)
    },
    options
  )
}

export const useQueryGetRegionsWithVisit = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetRegionsWithVisit", companyId],
    async () => {
      const api = await getApi()
      return await api
        .post("/mobile/report/query_v2", {
          companyId,
          report: "get_checkin_regions"
        })
        .then((response) => response.data.dados)
    },
    options
  )
}

export const useQueryGetCheckinParameterizationValues = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetCheckinParameterizationValues", companyId],
    async () => {
      const api = await getApi()

      return await api
        .get(`parameterization/checkin/${companyId}/`)
        .then((result) => result.data)
    },
    options
  )
}

export const useMutationCreateParameterizationCheckinEndDistance = (
  options: object
) =>
  useMutation(
    async ({
      companyId,
      max_end_distance
    }: CheckinParameterizationEndDistanceProps) => {
      const api = await getApi()

      const params = {
        company: companyId,
        max_end_distance
      }

      return api
        .post(`parameterization/checkin/`, params)
        .then((result) => result.data)
    },
    options
  )

export const useMutationeEditParameterizationCheckinEndDistance = (
  options: object
) =>
  useMutation(
    async ({
      companyId,
      max_end_distance,
      active_visit_days
    }: CheckinParameterizationEndDistanceProps) => {
      const api = await getApi()

      const params = {
        max_end_distance,
        active_visit_days
      }

      return api
        .put(`parameterization/checkin/${companyId}/`, params)
        .then((result) => result.data)
    },
    options
  )
